import algoliasearch from 'algoliasearch';
import { SearchResponse } from '@algolia/client-search';
import { makeServiceTerms } from '@/utils/algolia';
import { Sex } from '@/types-aggregatore';
import { SearchLocation } from '@/utils/search';
import { CLIENT_ENV } from '@/config/configuration';
import { AlgoliaClinicV2 } from '@/types/algolia-types';

const apiKey = CLIENT_ENV().NEXT_PUBLIC_ALGOLIA_SEARCH_KEY;
const appId = CLIENT_ENV().NEXT_PUBLIC_ALGOLIA_APP_ID;
const algoliaClient = algoliasearch(appId, apiKey);
const CLINIC_IDX = CLIENT_ENV().NEXT_PUBLIC_ALGOLIA_INDEX;
const clinicIndex = algoliaClient.initIndex(CLINIC_IDX);

const servicePriceAscIndex = algoliaClient.initIndex(`${CLINIC_IDX}_service_price_asc`);
const firstAvailabilityAscIndex = algoliaClient.initIndex(`${CLINIC_IDX}_first_availability_asc`);
const noDistinctIndex = algoliaClient.initIndex(`${CLINIC_IDX}_no-distinct`);

export enum ChannelFilterType {
  online = 'ONLINE',
  onsite = 'ONSITE',
  domiciliary = 'DOMICILIARY',
}

export enum DateFilterType {
  three_days = 'THREE_DAYS',
  seven_days = 'SEVEN_DAYS',
}

export enum PaymentFilterType {
  online = 'ONLINE',
  onsite = 'ONSITE',
}

export type FilterProps = {
  service?: string;
  specialization?: string;
  license?: string;
  excludeOnline?: boolean;
  excludeOnsite?: boolean;
  excludeDomicilary?: boolean;
  ambulatoryId?: string;
  dateFilter?: DateFilterType;
  channel?: ChannelFilterType[];
  preferFavorite?: boolean;
  doctorSex?: Sex;
  paymentType?: PaymentFilterType;
};

const dateFilterMs = {
  [DateFilterType.three_days]: 3,
  [DateFilterType.seven_days]: 7,
};

const getTimestampInDays = (daysFromNow: number): number => {
  const now = new Date();

  const targetDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + daysFromNow, 0, 0, 0, 0));

  return targetDate.getTime();
};

export enum SortingListingType {
  distanceAsc = 'DISTANCE_ASC',
  priceAsc = 'PRICE_ASC',
  firstAvailabilityAsc = 'FIRST_AVAILABILITY_ASC',
  noDistinct = 'NO_DISTINCT',
}

const sortingIndexes = {
  [SortingListingType.distanceAsc]: clinicIndex,
  [SortingListingType.priceAsc]: servicePriceAscIndex,
  [SortingListingType.firstAvailabilityAsc]: firstAvailabilityAscIndex,
  [SortingListingType.noDistinct]: noDistinctIndex,
};

type AlgoliaSearchRequestParams = {
  text: string;
  page?: number;
  filterObj?: null | FilterProps;
  hitsPerPage?: number;
  searchLocation?: null | SearchLocation;
  order?: SortingListingType;
};

export type AlgoliaSuggestionsRequestParams = {
  text: string;
  filterObj?: null | FilterProps;
};

const getFacetFilters = (filterObj: FilterProps | null) => {
  return [
    ...(filterObj?.service ? [`specializations.service:${filterObj?.service}`] : []),
    ...(filterObj?.specialization ? [`specializations.macroSpeciality:${filterObj?.specialization}`] : []),
    ...(filterObj?.doctorSex ? [`specializations.doctor.sex:${filterObj?.doctorSex}`] : []),
    ...(filterObj?.license ? [`license:${filterObj?.license}`] : []),
    // char '-' negate the facet filter https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/#usage-notes
    ...(filterObj?.excludeDomicilary ? [`specializations.channels:-DOMICILIARY`] : []),
    ...(filterObj?.excludeOnline ? [`specializations.channels:-VIDEO`] : []),
    ...(filterObj?.excludeOnsite ? [`specializations.channels:-STUDIO`] : []),
    ...(filterObj?.paymentType ? [`availablePayments:${filterObj.paymentType}`] : []),
    ...(filterObj?.channel ? [filterObj.channel.map(selectedChannel => `specializations.specialityChannels:${selectedChannel}`)] : []),
    ...(filterObj?.ambulatoryId ? [`ambulatoryId:${filterObj.ambulatoryId}`] : []),
  ];
};

// Used in search page
export const getAlgoliaSearchResponse = async ({
  text,
  searchLocation = null,
  order,
  page = 0,
  filterObj = null,
  hitsPerPage = 20,
}: AlgoliaSearchRequestParams): Promise<SearchResponse<AlgoliaClinicV2>> => {
  const currentIndex = order ? sortingIndexes[order] : clinicIndex;
  const optionalFilters = filterObj?.preferFavorite ? ['specializations.favorite:true'] : [];

  return await currentIndex?.search<AlgoliaClinicV2>(text, {
    page,
    numericFilters: [
      ...(filterObj?.dateFilter ? [`firstAvailableVisitTimestamp < ${getTimestampInDays(dateFilterMs[filterObj.dateFilter])}`] : []),
    ],
    facets: ['specializations.price', 'firstAvailableVisitTimestamp'],
    facetFilters: getFacetFilters(filterObj),
    optionalFilters,
    hitsPerPage,
    getRankingInfo: true,
    aroundRadius: !!searchLocation?.latitude && !!searchLocation?.longitude ? 30000 : 'all',
    ...(!!searchLocation?.latitude && !!searchLocation?.longitude
      ? { aroundLatLng: `${searchLocation.latitude}, ${searchLocation.longitude}` }
      : { aroundLatLngViaIP: true }),
    // alternativesAsExact: ['singleWordSynonym'],
  });
};

// Used in autocomplete
export const getAlgoliaSearchSuggestions = async ({ text, filterObj = null }: AlgoliaSuggestionsRequestParams) => {
  const clinicAutocompleteIndex = algoliaClient.initIndex('nomenclatore');
  const searchResponse = await clinicAutocompleteIndex.search<AlgoliaClinicV2>(text, {});

  console.log('searchResponse', searchResponse);
  const serviceTerms = makeServiceTerms(searchResponse);

  return [...serviceTerms];
};

export type ClinicSearchMatcherResult = {
  matchLevel?: string;
  matchedWords: string[];
  value?: string;
};

export type ClinicSearchResult = SearchResponse<AlgoliaClinicV2>;
