import { CheckoutFlowType, CheckoutStatusType } from '@/costants/enum';
import { AggregatorReservationStatus, UserInclinicPayment } from '@/types-aggregatore';
import { onBehalfOfDefaultValue } from '@/utils/onBehalfOf';
import { StateCreator } from 'zustand';

interface CheckoutHandlers {
  updateCheckoutFormField: <K extends keyof CheckoutFormFields>(field: K, value: CheckoutFormFields[K]) => void;
  updateUserInfo: (userInfo: UserInfo) => void;
  resetCheckout: () => void;
  resetCheckoutExceptUserInfo: () => void;
  resetCheckoutPaymentInfo: () => void;
  setCheckoutFlow: (checkoutFlow: CheckoutFlowType) => void;
  setCheckoutStep: (formStep: FormStep) => void;
  setCheckoutStatusCompleted: () => void;
}

export enum FormStep {
  Information,
  Payment,
  BookingConfirmation,
}

//userId is used to save the user from mobile APP and pass it to the GA4 events
type UserInfo = {
  userId?: string;
  firstName: string;
  secondName: string;
  taxCode: string;
  phoneNumber: string;
  email: string;
};

type OtherCheckoutInfo = {
  formStep: FormStep;
  paymentId: string;
  privacyPolicyAgreement: boolean;
  noAssuranceAgreement: boolean;
  dataManagementAgreement: boolean;
  tantoSvagoAgreement: boolean;
  userPaymentSelected: UserInclinicPayment;
  homeVisit: boolean;
  homeVisitDistrict: string;
  homeVisitCity: string;
  homeVisitStreet: string;
  homeVisitStreetNumber: string;
  homeVisitCap: string;
  reservationStatus: AggregatorReservationStatus | undefined;
  postReservationsInstructions?: string | null;
  onBehalfOfValue: string;
  onBehalfOfName: string;
  onBehalfOfSurname: string;
  onBehalfOfTaxCode: string;
  isVideoConsultation: boolean;
  totalAmount: number;
  couponDiscount: number;
  couponCode: string | undefined;
  paymentMethod?: string;
  currentCheckoutPathname: string;
  billingDistrict?: string;
  billingCity?: string;
  billingStreet?: string;
  billingStreetNumber?: string;
  billingCap?: string;
  isDomiciliarySameAsBillingAddress: boolean;
};

export type CheckoutFormFields = UserInfo & OtherCheckoutInfo;

const initialUserInfo: UserInfo = {
  userId: undefined,
  firstName: '',
  secondName: '',
  taxCode: '',
  phoneNumber: '',
  email: '',
};

const initialOtherCheckoutInfo: OtherCheckoutInfo = {
  formStep: FormStep.Information,
  paymentId: '',
  privacyPolicyAgreement: false,
  noAssuranceAgreement: false,
  dataManagementAgreement: false,
  tantoSvagoAgreement: false,
  userPaymentSelected: UserInclinicPayment.Online,
  homeVisit: false,
  homeVisitDistrict: '',
  homeVisitCity: '',
  homeVisitStreet: '',
  homeVisitStreetNumber: '',
  homeVisitCap: '',
  reservationStatus: undefined,
  postReservationsInstructions: undefined,
  onBehalfOfValue: onBehalfOfDefaultValue(),
  onBehalfOfName: '',
  onBehalfOfSurname: '',
  onBehalfOfTaxCode: '',
  isVideoConsultation: false,
  totalAmount: 0,
  couponDiscount: 0,
  couponCode: undefined,
  paymentMethod: undefined,
  currentCheckoutPathname: '',
  billingDistrict: '',
  billingCity: '',
  billingStreet: '',
  billingStreetNumber: '',
  billingCap: '',
  isDomiciliarySameAsBillingAddress: false,
};

// Combiniamo i due oggetti per creare il form iniziale
const initialCheckoutForm: CheckoutFormFields = {
  ...initialUserInfo,
  ...initialOtherCheckoutInfo,
};

interface CheckoutForm {
  checkoutForm: CheckoutFormFields;
}

interface CheckoutFlow {
  checkoutFlow: CheckoutFlowType;
}

interface CheckoutStatus {
  checkoutStatus: CheckoutStatusType;
}

export interface CheckoutState extends CheckoutHandlers, CheckoutForm, CheckoutFlow, CheckoutStatus {}

export const createCheckoutSlice: StateCreator<CheckoutState, [], [], CheckoutState> = set => ({
  checkoutForm: initialCheckoutForm,
  checkoutFlow: CheckoutFlowType.RESERVATION,
  checkoutStatus: CheckoutStatusType.NOT_COMPLETED,

  updateCheckoutFormField: <K extends keyof CheckoutFormFields>(field: K, value: CheckoutFormFields[K]): void =>
    set(state => ({
      checkoutForm: {
        ...state.checkoutForm,
        [field]: value,
      },
    })),

  updateUserInfo: (userInfo: UserInfo) =>
    set(state => ({
      checkoutForm: {
        ...state.checkoutForm,
        ...userInfo,
      },
    })),

  resetCheckout: () =>
    set({
      checkoutForm: { ...initialCheckoutForm },
      checkoutFlow: CheckoutFlowType.RESERVATION,
      checkoutStatus: CheckoutStatusType.NOT_COMPLETED,
    }),

  resetCheckoutExceptUserInfo: () =>
    set(state => ({
      checkoutForm: {
        ...initialOtherCheckoutInfo,
        firstName: state.checkoutForm.firstName,
        secondName: state.checkoutForm.secondName,
        taxCode: state.checkoutForm.taxCode,
        phoneNumber: state.checkoutForm.phoneNumber,
        email: state.checkoutForm.email,
        userId: state.checkoutForm.userId,
      },
      checkoutFlow: CheckoutFlowType.RESERVATION,
      checkoutStatus: CheckoutStatusType.NOT_COMPLETED,
    })),

  resetCheckoutPaymentInfo: () =>
    set(state => ({
      checkoutForm: {
        ...state.checkoutForm,
        couponCode: '',
        couponDiscount: 0,
        paymentId: '',
      },
    })),

  setCheckoutFlow: (checkoutFlow: CheckoutFlowType) =>
    set({
      checkoutFlow,
    }),

  setCheckoutStep: (formStep: FormStep) =>
    set(state => ({
      checkoutForm: {
        ...state.checkoutForm,
        formStep,
      },
    })),

  setCheckoutStatusCompleted: () =>
    set({
      checkoutStatus: CheckoutStatusType.COMPLETED,
    }),
});
