import { FC, useCallback, useEffect, useState } from 'react';
import { Sheet } from '@/app/_components/Sheet';
import { ELButton, ELLoader } from '@davincihealthcare/elty-design-system-react';
import Modal from '@/app/_components/modal';
import { ArrowRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { RecapSection } from '@/app/_components/widgets/listing/cardV2/RecapSection';
import { useAvailabilitySlots } from '@/app/_components/widgets/listing/_hooks/useAvailabilitySlots';
import { SlotAvailability } from '@/app/_components/widgets/listing/types';
import { TimeButton } from '@/app/_components/widgets/listing/cardV2/TimeButton';
import { getDayTitle } from '@/app/_components/widgets/listing/_utils/time';
import { useIsTablet } from '@/app/_hooks/useIsScreen';

interface AvailabilityModalProps {
  specialityId: string;
  doctorId: string;
  workgroupId: string;
  onClose: () => void;
  onSubmit: (selectedSlot: SlotAvailability) => void | Promise<void>;
  initialSelectedDay: number | 'OTHER' | null;
}

const scrollToElement = (id: string) => {
  setTimeout(() => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, 50);
};

export const AvailabilityModal: FC<AvailabilityModalProps> = ({
  specialityId,
  doctorId,
  workgroupId,
  onClose,
  onSubmit,
  initialSelectedDay,
}) => {
  const [selectedSlot, setSelectedSlot] = useState<SlotAvailability | null>(null);
  const [hasBeenInitialized, setHasBeenInitialized] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { groupedDays, sortedDates, isInitialLoading, fetchNextPage, isLoadingMore, hasMoreData } = useAvailabilitySlots({
    specialityId,
    doctorId,
    workgroupId,
  });

  const isTabletAndSmaller = useIsTablet();
  /*
    Wait for the first load to complete
    Scroll to the selected day or the first available slot if initialSelectedDay is an existing day
    Scroll to the "show more times" button if initialSelectedDay is OTHER and there are slots available, fetch also additional slots
  */
  useEffect(() => {
    // If the component has been initialized or there are no slots, do nothing
    if (hasBeenInitialized || sortedDates.length === 0 || isInitialLoading) return;
    // If the initial selected day is OTHER and there are slots, scroll to the "show more times" button and fetch additional slots
    if (initialSelectedDay === 'OTHER' && sortedDates.length > 0) {
      // Scroll to sixth day or last day if there are less than 6 days
      const targetDay = Math.min(6, sortedDates.length);
      scrollToElement(`day-${sortedDates[targetDay - 1]}`);
      if (sortedDates.length < 6) fetchNextPage();
    } else if (initialSelectedDay && groupedDays[initialSelectedDay]) {
      const firstSlot = groupedDays[initialSelectedDay][0];
      if (firstSlot) {
        setSelectedSlot(firstSlot);
        scrollToElement(`day-${initialSelectedDay}`);
      }
    }
    setHasBeenInitialized(true);
  }, [initialSelectedDay, groupedDays, sortedDates.length, hasBeenInitialized, fetchNextPage, isInitialLoading, sortedDates]);

  const handleLoadMore = useCallback(async () => {
    await fetchNextPage();
  }, [fetchNextPage]);

  const handleSubmit = useCallback(async () => {
    if (!selectedSlot) return;
    setIsSubmitting(true);
    try {
      await onSubmit(selectedSlot);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }, [selectedSlot, onSubmit]);

  const continueButton = (
    <ELButton
      aria-label="Continua"
      label="Continua"
      variant="filled"
      color="primary"
      trailingIcon={<ArrowRightIcon />}
      size="large"
      disabled={!selectedSlot || isSubmitting}
      onClick={handleSubmit}
    />
  );

  const dayList = isInitialLoading ? (
    <div className="flex h-full w-full items-center justify-center">
      <ELLoader />
    </div>
  ) : (
    <div className="flex flex-col gap-6">
      {sortedDates.map(timestamp => {
        const slots = groupedDays[timestamp];

        return (
          <div key={timestamp} id={`day-${timestamp}`} className="flex flex-col gap-3">
            <h3 className="font-semibold capitalize">{getDayTitle(timestamp)}</h3>
            <div className="grid grid-cols-[repeat(auto-fill,minmax(70px,1fr))] gap-2">
              {slots.map(slot => (
                <TimeButton
                  key={slot.start}
                  dataCy={`slot-button`}
                  dataTimestamp={slot.start}
                  title={new Date(slot.start).toLocaleTimeString('it', { hour: '2-digit', minute: '2-digit' })}
                  isSelected={selectedSlot?.start === slot.start}
                  onClick={() => setSelectedSlot(slot)}
                />
              ))}
            </div>
          </div>
        );
      })}
      <div className="flex w-full flex-col items-center gap-2 md:flex-row">
        <ELButton
          id="show-more-times"
          aria-label={hasMoreData ? 'Mostra altri orari' : 'Nessun altro orario disponibile'}
          label={hasMoreData ? 'Mostra altri orari' : 'Nessun altro orario disponibile'}
          variant="text"
          disabled={isInitialLoading || isLoadingMore || !hasMoreData}
          color="primary"
          size="small"
          onClick={handleLoadMore}
        />
        {isLoadingMore && <ELLoader />}
      </div>
    </div>
  );

  if (isTabletAndSmaller) {
    return (
      <Modal isModalOpen={true} onClose={onClose} className="m-0 h-full max-h-full w-full max-w-full px-4 pt-5">
        <div className="relative flex h-full max-h-full w-full max-w-full flex-col gap-6">
          <div className="grid grid-cols-[1.5rem_1fr_1.5rem] items-center justify-end text-right">
            {/*Future back button*/}
            <div />
            <h2 className="text-center text-base font-semibold text-primary">Scelta orario</h2>
            <button aria-label="Chiudi" className="focus:outline-none" onClick={onClose}>
              <XMarkIcon className="w-full " />
            </button>
          </div>

          <RecapSection selectedSlot={selectedSlot} />

          <div className="scrolling-shadow flex-1 overflow-y-auto pb-28">{dayList}</div>

          <div className="fixed bottom-0 left-0 w-full px-4 [&>button]:w-full">
            <div className="h-6 w-full bg-gradient-to-t from-white via-white/90 to-transparent" />
            {continueButton}
            <div className="h-6 w-full bg-gradient-to-b from-white via-white/90 to-transparent" />
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Sheet isOpen={true} onClose={onClose}>
      <div className="relative flex h-full max-h-full flex-col gap-6 px-20 pb-5 pt-10">
        <h2 className="text-2xl font-semibold text-primary">Scelta orario</h2>
        <RecapSection selectedSlot={selectedSlot} />
        <div className="scrolling-shadow flex-1 overflow-y-auto">{dayList}</div>
        <div className="w-fit">{continueButton}</div>
      </div>
    </Sheet>
  );
};
