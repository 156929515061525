import { Lora } from 'next/font/google';
import { StoryblokReactComponent } from './componentsMap';
import { HowWorkCardsStoryblok } from '@/types/types-storyblok';

import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx/lite';
import { StoryblokComponent } from '@/app/(booking)/prenota-visita/_storyblok/storyblokComponent';

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

export const StoryblokHowWorkCards: StoryblokReactComponent<HowWorkCardsStoryblok> = ({ component, story, additionalData }) => {
  return (
    <section className={'shadow-1 flex flex-col items-center justify-center gap-7 bg-secondary-light-active px-4 py-14 md:px-20 lg:py-20'}>
      <h2 className="text-3xl">
        {render(component.title, {
          markResolvers: {
            [MARK_BOLD]: child => <span className={clsx(lora.className, 'text-elty-green')}>{child}</span>,
          },
        })}
      </h2>
      <ul className={clsx('mx-auto flex max-w-screen-xl flex-col gap-4 md:grid-cols-2 md:gap-8 lg:grid')}>
        {component.cardsList.map(card => (
          <StoryblokComponent story={story} key={card._uid} component={card} additionalData={additionalData} />
        ))}
      </ul>
    </section>
  );
};
