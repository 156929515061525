import { DAVINCI_CDN } from '@/app/_constants/defaultValues';
import { resolveWebLink } from '@/storyblok/utils/link';
import { FooterStoryblok } from '@/types/types-storyblok';
import Image from 'next/image';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';

const FooterBottom: FunctionComponent<{ component: FooterStoryblok; minimal: boolean }> = ({ component: footer, minimal }) => (
  <footer className="bg-neutral-surface" data-cy="footer">
    <div className="space-between container mx-auto flex items-center justify-between px-1 py-12  md:px-2 lg:px-4">
      {minimal ? (
        <Image src={`${DAVINCI_CDN}/elty/elty-logo.svg`} width={108} height={33} alt="Logo" />
      ) : (
        <div className="flex flex-col items-center justify-center lg:flex-row">
          <span className="text-xs text-primary">
            <p>Società parte </p>
            <p>del Gruppo</p>
          </span>
          <Image
            src={`${DAVINCI_CDN}/inclinic/images/unipolDark2025.png`}
            width={300}
            height={150}
            alt="Unipol logo"
            className="h-16 w-auto"
          />
        </div>
      )}

      <div className="flex-row text-xs text-neutral-lighter lg:col-span-3 lg:col-start-2 lg:row-start-5 lg:flex lg:items-end lg:justify-end lg:gap-2">
        <div className="text-neutral-lighter lg:whitespace-nowrap">{footer.bottomText}</div>
        <nav>
          <ul className="flex flex-row flex-wrap items-center lg:flex-nowrap" role="menu">
            {footer.legal?.map((link, i) => (
              <React.Fragment key={link._uid}>
                <li
                  role="presentation"
                  className="whitespace-nowrap text-neutral-lighter transition-colors hover:text-black focus:text-black"
                >
                  {link.link && (
                    <Link role="menuitem" href={resolveWebLink(link.link, { name: 'footer legals link item', link })}>
                      {link.title}
                    </Link>
                  )}
                </li>
                {i !== (footer.legal?.length || 0) - 1 && (
                  <li className="px-1" role="separator">
                    |
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  </footer>
);

export default FooterBottom;
