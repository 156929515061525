import { ELButton, ELLink } from '@davincihealthcare/elty-design-system-react';

type NoAvailabilitiesInfoProps = {
  title: string;
  description?: string;
  path?: string;
  onClick?: () => void;
};

export const NoAvailabilitiesInfo: React.FC<NoAvailabilitiesInfoProps> = ({ path, title, description, onClick }) => (
  <div className="flex h-full flex-col items-center justify-center gap-5 text-center">
    <p className="text-base font-medium text-neutral-lighter">{title}</p>
    {onClick && description && (
      <ELButton aria-label={description} size="small" variant="outlined" color="primary" label={description} onClick={onClick} />
    )}
    {path && description && (
      <ELLink aria-label={description} size="small" variant="outlined" color="primary" label={description} href={path} />
    )}
  </div>
);
