import { ListingInfo } from '@/app/_components/widgets/listing/types';
import { formatPrice } from './checkout';
import { CheckoutFlowType } from '@/costants/enum';
import { LISTING_PAGE_FORMATTED_CALLCENTER_TELEPHONE_NUMBER } from '@/costants';
import { AlgoliaClinicV2, License } from '@/types/algolia-types';
import client from '@/utils/apolloClient';
import { SendPurchaseEventDocument } from '@/types-aggregatore';

type EcommerceObj = {
  item_list_name?: string;
  currency?: string;
  value?: string;
  transaction_id?: string;
  items?: GoogleTagManagerItem[];
};

export type GoogleTagManagerItem = {
  item_id?: string;
  item_name?: string;
  item_brand?: string;
  item_variant?: string;
  item_category?: string;
  item_category5?: string;
  price?: string;
  index?: number;
  user_id?: string;
};

export const fireGoogleTagManagerEvent = (eventName: string, obj: any = {}) => {
  const dataLayer = window?.dataLayer;
  if (dataLayer) {
    dataLayer.push({ event: eventName, ...obj });
  }
};

const fireGoogleTagManagerEcommerceEvent = (eventName: string, obj: EcommerceObj) => {
  if (typeof window === 'undefined') return;

  const dataLayer = window?.dataLayer;
  if (dataLayer) {
    dataLayer.push({
      event: eventName,
      ecommerce: obj,
    });
  }
};

export const fireViewItemListEvent = (itemListName: string, items: GoogleTagManagerItem[]) => {
  fireGoogleTagManagerEcommerceEvent('view_item_list', {
    item_list_name: itemListName,
    items: items,
  });
};

export const fireViewItemEvent = (price: string, item: GoogleTagManagerItem) => {
  fireGoogleTagManagerEcommerceEvent('view_item', {
    currency: 'EUR',
    value: price,
    items: [item],
  });
};

export const fireSelectItemEvent = (price: string, item: GoogleTagManagerItem) => {
  fireGoogleTagManagerEcommerceEvent('select_item', {
    currency: 'EUR',
    value: price,
    items: [item],
  });
};

export const fireAddToCartEvent = (price: string, item: GoogleTagManagerItem) => {
  fireGoogleTagManagerEcommerceEvent('add_to_cart', {
    currency: 'EUR',
    value: price,
    items: [item],
  });
};

export const fireConfirmTimeSelectionEvent = (price: string, item: GoogleTagManagerItem) => {
  fireGoogleTagManagerEcommerceEvent('confirm_time_selection', {
    currency: 'EUR',
    value: price,
    items: [item],
  });
};

export const fireViewCartEvent = (price: string, item: GoogleTagManagerItem) => {
  fireGoogleTagManagerEcommerceEvent('view_cart', {
    currency: 'EUR',
    value: price,
    items: [item],
  });
};

export const fireBeginCheckoutEvent = (price: string, item: GoogleTagManagerItem, checkoutFlow: CheckoutFlowType) => {
  if (checkoutFlow === CheckoutFlowType.RESERVATION) {
    fireGoogleTagManagerEcommerceEvent('begin_checkout', {
      currency: 'EUR',
      value: price,
      items: [item],
    });
  }
};

export const fireAddShippingInfoEvent = (price: string, item: GoogleTagManagerItem) => {
  fireGoogleTagManagerEcommerceEvent('add_shipping_info', {
    currency: 'EUR',
    value: price,
    items: [item],
  });
};

export const fireAddPaymentInfoEvent = (price: string, item: GoogleTagManagerItem, checkoutFlow: CheckoutFlowType) => {
  if (checkoutFlow === CheckoutFlowType.RESERVATION) {
    fireGoogleTagManagerEcommerceEvent('add_payment_info', {
      currency: 'EUR',
      value: price,
      items: [item],
    });
  }
};

export const firePurchaseEvent = async (
  price: string,
  reservationId: string,
  item: GoogleTagManagerItem,
  checkoutFlow: CheckoutFlowType,
  userId?: string,
) => {
  if (checkoutFlow === CheckoutFlowType.RESERVATION) {
    item.user_id = userId;
    fireGoogleTagManagerEcommerceEvent('purchase', {
      currency: 'EUR',
      value: price,
      transaction_id: reservationId,
      items: [item],
    });
  }

  // If userId means that the user is from mobile APP
  if (userId) {
    await client.query({
      query: SendPurchaseEventDocument,
      variables: {
        payload: {
          item_id: item.item_id,
          item_name: item.item_name,
          item_brand: item.item_brand,
          item_variant: item.item_variant,
          item_category: item.item_category,
          item_category5: item.item_category5,
          price: item.price,
          user_id: userId,
        },
      },
    });
  }
};

export const trackLicensedItemInSearchList = (searchResult?: ListingInfo) => {
  if (!searchResult) return;
  const licensedClinics = searchResult?.results
    ?.filter((clinic: AlgoliaClinicV2) => clinic.license !== License.None)
    ?.map((res: AlgoliaClinicV2, index: number) => {
      return {
        item_id: res?.objectID,
        item_name: res.specializations?.label ?? '',
        item_brand: res?.name ?? '',
        item_variant: res.specializations?.macroSpeciality ?? '',
        item_category: `${res?.city}`,
        price: formatPrice({ amount: res?.specializations?.price }),
        index: index,
      };
    });
  if (licensedClinics?.length) {
    fireViewItemListEvent(searchResult?.title ?? '', licensedClinics);
  }
};

export const trackPhoneNumberClick = () => {
  fireGoogleTagManagerEvent('phone_conversion_number', { phone_conversion_number: LISTING_PAGE_FORMATTED_CALLCENTER_TELEPHONE_NUMBER });
};
