import { StoryblokReactComponent } from '@/app/(booking)/prenota-visita/_storyblok/componentsMap';
import { SmallCardWithImageStoryblok } from '@/types/types-storyblok';
import Image from 'next/image';

export const StoryblokSmallCardWithImage: StoryblokReactComponent<SmallCardWithImageStoryblok> = ({ component: card }) => {
  return (
    <li key={card._uid} className="mx-auto flex max-w-xs flex-grow flex-row justify-center gap-5">
      <Image className="object-contain" src={card.image.filename} alt={card.image.alt ?? ''} height={80} width={80} />
      <div className="flex flex-col gap-3">
        <h3 className="text-xl font-medium">{card.title}</h3>
        <p className="text-text-body">{card.content}</p>
      </div>
    </li>
  );
};
