import { useCallback, useEffect, useMemo, useState } from 'react';
import { makeFullAddress } from '@/utils/common';
import { makeClinicSlug } from '@/utils/shared';
import { useIsTantosvago } from '@/app/_hooks/useIsTantosvago';
import { CardAvailabilities } from './CardAvailabilities';
import { ClinicHeader } from './ClinicHeader';
import { BookingActions } from './BookingActions';
import { NoAvailabilitiesInfo } from './NoAvailabilitiesInfo';
import PriceSection from './PriceSection';
import { AlgoliaClinicV2, License, PaymentType, SpecialityChannel } from '@/types/algolia-types';
import { ELBadge } from '@davincihealthcare/elty-design-system-react';
import { HomeIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import { AvailabilityModal } from '@/app/_components/widgets/listing/cardV2/AvailabilityModal';
import { formatPrice, getCheckoutURL } from '@/utils/checkout';
import { SlotAvailability } from '@/app/_components/widgets/listing/types';
import { ROUTES } from '@/routes';
import { useRouter } from 'next/navigation';
import { ChangeSpecializationWrapper } from '@/app/_components/widgets/listing/cardV2/ChangeSpecializationWrapper';
import { trackAddToCartEvent, MetaPixelProduct } from '@/utils/trackingMeta-pixel';
import { fireAddToCartEvent, fireConfirmTimeSelectionEvent } from '@/utils/trackingGtm';
import { DavinciLogo } from '@/app/_components/widgets/listing/cardV2/DavinciLogo';
import { useInView } from '@/app/_hooks/useIsInView';
import { useQuery } from '@apollo/client';
import { AvailabilityGroupByDateDocument } from '@/types-aggregatore';
import { getStartOfDayTime } from '@/app/_components/widgets/listing/_utils/time';
import { useIsDesktop } from '@/app/_hooks/useIsScreen';

export type AlgoliaCardProps = Pick<
  AlgoliaClinicV2,
  | 'license'
  | 'address'
  | 'cap'
  | 'city'
  | 'logoUrl'
  | 'name'
  | 'specializations'
  | 'domiciliaryCare'
  | 'ambulatoryId'
  | 'availablePayments'
  | 'slug'
  | 'objectID'
>;

type Props = {
  item: AlgoliaCardProps;
  index: number;
  showAllServicesEnabled?: boolean;
  showPhoneNumber?: boolean;
  showNoAvailabilitiesCallToAction?: boolean;
};

const numberOfCardsToAlwaysLoad = 2;

const CardV2: React.FC<Props> = ({
  item,
  index,
  showAllServicesEnabled = true,
  showPhoneNumber = true,
  showNoAvailabilitiesCallToAction = true,
}) => {
  const isLicensed = item?.license && (item.license as License) !== License.None;
  const router = useRouter();

  const { address, cap, city, logoUrl, name: clinicName } = item;

  const isOnline = useMemo(
    () => item.specializations.specialityChannels?.includes(SpecialityChannel.Online),
    [item.specializations.specialityChannels],
  );

  const isDesktopAndSmaller = useIsDesktop();
  const isDomicile = item.domiciliaryCare;
  const hasOnsitePayment = useMemo(() => item.availablePayments?.includes(PaymentType.Onsite), [item.availablePayments]);

  const { label: specialityName, id: specialityId, hasDynamicPrice, price } = item.specializations;
  const doctorId = item.specializations.doctors?.[0]?.id;
  const workgroupId = item.ambulatoryId;
  const isTantoSvago = useIsTantosvago();
  const completeAddress = useMemo(() => makeFullAddress(address, cap, city) ?? '', [address, cap, city]);
  const [selectedDay, setSelectedDay] = useState<number | 'OTHER' | null>(null);
  const allClinicServicesLink = useMemo(
    () => makeClinicSlug(item, specialityName, specialityId, doctorId),
    [item, specialityId, specialityName, doctorId],
  );
  const clinicLink = useMemo(() => makeClinicSlug(item), [item]);
  const [showAllServicesModal, setShowAllServicesModal] = useState(false);

  const { isInView, ref } = useInView();

  const shouldLoadAvailabilities = useMemo(() => {
    return isLicensed && specialityId && doctorId && (index < numberOfCardsToAlwaysLoad || isInView);
  }, [isLicensed, specialityId, doctorId, index, isInView]);

  const now = useMemo(() => new Date().getTime(), []);

  const { data, loading: isLoadingAvailabilities } = useQuery(AvailabilityGroupByDateDocument, {
    variables: {
      startDate: now,
      specialityId: specialityId!,
      doctorId: doctorId!,
      workgroupId: workgroupId,
      days: 30,
    },
    skip: !shouldLoadAvailabilities,
  });

  const groupedDays = useMemo(
    () => data?.availabilityGroupByDate.map(day => ({ date: getStartOfDayTime(day.date), availabilityCount: day.availabilityCount })) ?? [],
    [data],
  );
  const hasAvailableSlots = groupedDays.length > 0;
  useEffect(() => {
    // Select first day if no day is selected
    if (!selectedDay && hasAvailableSlots) {
      setSelectedDay(groupedDays[0].date);
    }
  }, [selectedDay, groupedDays, hasAvailableSlots]);
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);

  const selectAndOpenAvailabilityModal = (day: number | 'OTHER') => {
    setSelectedDay(day);
    setIsAvailabilityModalOpen(true);
    const metaPixelProduct: MetaPixelProduct = {
      value: item.specializations.price || 0,
      currency: 'EUR',
      content_ids: item.specializations.id || '',
      content_type: item.specializations.specialityChannels?.join(',') || '',
      content_name: item.specializations.label || '',
      content_category: item.specializations.macroSpeciality || '',
    };
    trackAddToCartEvent(metaPixelProduct);

    fireAddToCartEvent(`${item?.specializations?.price}`, {
      item_name: item?.specializations?.label,
      item_brand: item?.name,
      item_variant: item?.specializations?.label,
      item_category: item?.address ?? undefined,
      price: formatPrice({ amount: item?.specializations?.price }),
      index: 0,
    });
  };

  const handleSubmit = useCallback(
    async (selectedSlot: SlotAvailability) => {
      if (!selectedSlot) return;
      fireConfirmTimeSelectionEvent(formatPrice({ amount: price }), {
        item_name: item?.specializations?.label,
        item_brand: item?.name,
        item_variant: item?.specializations?.label,
        item_category: item?.address ?? undefined,
        price: formatPrice({ amount: item?.specializations?.price }),
        index: 0,
      });
      const checkoutUrl = getCheckoutURL({
        pathname: ROUTES.CHECKOUT_RECAP.pathname,
        availabilitySlotId: selectedSlot.id,
      });

      router.push(checkoutUrl);
      // Wait for the checkout page to load
      await new Promise(resolve => setTimeout(resolve, 3000));
    },
    [item, price, router],
  );

  return (
    <div ref={ref} className="overflow-x-hidden rounded-lg shadow-cardListing">
      {showAllServicesModal && (
        <ChangeSpecializationWrapper
          workgroupId={workgroupId}
          domiciliaryCare={isDomicile}
          onCancel={() => setShowAllServicesModal(false)}
          onNewSlot={slot => {
            setShowAllServicesModal(false);
            handleSubmit(slot);
          }}
        />
      )}
      {hasOnsitePayment && !isTantoSvago && (
        <div className="flex items-center justify-center gap-2 bg-brandGreen-200 px-6 py-2 text-sm font-semibold text-primary lg:flex-row-reverse lg:justify-start">
          <DavinciLogo />
          {<span>Prenota ora e paga in struttura</span>}
        </div>
      )}
      <div
        className={`grid grid-cols-1 gap-4 bg-neutral-surface pb-4 lg:grid-cols-2 lg:gap-5 lg:pb-6 ${isLicensed ? 'min-h-[504px] lg:min-h-[262px]' : ''}`}
      >
        <div className="flex flex-col gap-4 px-4 pt-4 lg:min-w-[300px] lg:pt-6">
          <div className="flex flex-col">
            <ClinicHeader
              logoSrc={logoUrl ?? ''}
              clinicLink={clinicLink}
              clinicName={clinicName}
              isLicensed={isLicensed}
              completeAddress={completeAddress}
              allClinicServicesLink={allClinicServicesLink}
              onShowAllServices={() => setShowAllServicesModal(true)}
              showAllServicesEnabled={showAllServicesEnabled}
            />
          </div>
          <div className="-ml-4 h-0.5 w-[calc(100%+2rem)] bg-slate-100 lg:hidden" />

          <div className="flex flex-1 items-center justify-between gap-3">
            <div className="flex flex-col gap-1 overflow-hidden">
              <h3 className="break-words text-base font-semibold text-neutral-darker">{specialityName}</h3>
              {isDomicile && <ELBadge label="A domicilio" color="primary" size="medium" Icon={HomeIcon} />}
              {isOnline && <ELBadge label="Prestazione online" color="primary" size="medium" Icon={VideoCameraIcon} />}
            </div>
            {isLicensed && <PriceSection hasDynamicPrice={hasDynamicPrice} isLicensedClinic={isLicensed} price={price} />}
          </div>
        </div>
        <div className="flex w-full flex-col justify-between gap-4 px-4 lg:px-6 lg:pt-6">
          {!isLicensed || !specialityId || !doctorId ? (
            <NoAvailabilitiesInfo
              title="Per prenotare contatta la struttura"
              {...(showNoAvailabilitiesCallToAction && {
                description: 'Visualizza il profilo',
                path: allClinicServicesLink,
              })}
            />
          ) : (
            <>
              {isAvailabilityModalOpen && (
                <AvailabilityModal
                  specialityId={specialityId}
                  doctorId={doctorId}
                  workgroupId={workgroupId}
                  initialSelectedDay={selectedDay}
                  onClose={() => setIsAvailabilityModalOpen(false)}
                  onSubmit={handleSubmit}
                />
              )}
              <CardAvailabilities
                selectedDay={selectedDay}
                onDayClick={day => selectAndOpenAvailabilityModal(day)}
                onOtherAvailabilitiesClick={() => selectAndOpenAvailabilityModal('OTHER')}
                onNoAvailabilitiesClick={() => setShowAllServicesModal(true)}
                isLoading={isLoadingAvailabilities}
                groupedDays={groupedDays}
              />
              {(hasAvailableSlots || isLoadingAvailabilities) && (
                <BookingActions
                  loading={isLoadingAvailabilities}
                  onClick={() => setIsAvailabilityModalOpen(true)}
                  size={isDesktopAndSmaller ? 'medium' : 'small'}
                  showPhoneNumber={showPhoneNumber}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardV2;
