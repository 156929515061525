'use client';
import Image from 'next/image';
import React, { useCallback, useState } from 'react';

import { useIsTablet } from '@/app/_hooks/useIsScreen';
import Modal from '@/app/_components/modal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { DAVINCI_CDN } from '@/costants/defaultValues';
import NavigationMenu from '@/app/_components/layouts/headerMenu/navigationMenu';
import ReactPopover from '@/app/_components/layouts/headerMenu/reactPopover';
import { useIsTantosvago } from '@/app/_hooks/useIsTantosvago';

const logoFull = `${DAVINCI_CDN}/elty/elty-logo.svg`;

const HeaderMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const togglePopover = useCallback(() => setIsOpen(open => !open), [setIsOpen]);

  const isTabletAndSmaller = useIsTablet();
  const isWelfare = useIsTantosvago();

  if (isWelfare) return;

  return (
    <>
      <ReactPopover isVisible={isOpen && !isTabletAndSmaller} setIsVisible={setIsOpen}>
        <div className="rounded-xl border border-neutral-100 bg-white pb-4 shadow-md">
          <NavigationMenu isMobile={isTabletAndSmaller} />
        </div>
      </ReactPopover>
      <button
        onClick={togglePopover}
        className={`relative h-8 w-8 rounded-lg p-2 ${isOpen ? 'bg-neutral-active' : ''}`}
        aria-controls="navbar-menu"
        aria-expanded={isOpen}
      >
        <Image
          className="h-full w-full"
          src={isOpen ? `${DAVINCI_CDN}/inclinic/icons/close.png` : `${DAVINCI_CDN}/inclinic/icons/menu-mobile.png`}
          alt="Menu icon"
          width={isOpen ? 24 : 30}
          height={isOpen ? 24 : 20}
        />
      </button>

      <Modal
        isModalOpen={isOpen && isTabletAndSmaller}
        onClose={() => setIsOpen(false)}
        className="m-0 h-full max-h-full w-full max-w-full pb-14 pt-5"
      >
        <div className="relative mb-4 flex h-7 w-full items-center justify-between px-4">
          <Image src={logoFull} alt={'Elty'} height={56} width={722} className="left-0 h-full max-h-full w-auto" />
          <XMarkIcon className="h-7 w-7 cursor-pointer md:mr-5" aria-label="Chiudi" onClick={() => setIsOpen(false)} />
        </div>
        <NavigationMenu isMobile={isTabletAndSmaller} />
      </Modal>
    </>
  );
};

export default HeaderMenu;
