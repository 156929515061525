export interface AlgoliaClinicV2 {
  objectID: string;
  ambulatoryId: string;
  name: string;
  description: string;
  website: string;
  logoUrl?: string;
  license: License;
  licenseOrder: number;
  address?: string | null;
  cap?: string | null;
  city?: string | null;
  district: string;
  slug: string;
  _geoloc?: {
    lat: number;
    lng: number;
  };
  phones?: string[] | null;
  specializations: Speciality;
  domiciliaryCare: boolean;
  offerOnlineServices: boolean;
  /** First available date for a visit in UNIX timestamp format */
  firstAvailableVisitTimestamp?: number;
  availablePayments: PaymentType[];
  /** Epoch timestamp for last import */
  existsAvailabilities?: boolean;
  importRun?: number;
  distinctId: string;
  _rankingInfo?: {
    geoDistance: number;
  };
}
export type Speciality = {
  doctors?: Doctor[];
  favorite?: boolean;
  id?: string;
  label: string;
  macroSpeciality?: string | null;
  macroSpecialityKey?: string | null;
  price: number;
  specialityChannels?: SpecialityChannel[];
  hasDynamicPrice: boolean;
};
export enum SpecialityChannel {
  Domiciliary = 'DOMICILIARY',
  Online = 'ONLINE',
  Onsite = 'ONSITE',
}
export type Doctor = {
  id: string;
  isNameVisible: boolean;
  name: string;
  sex?: Sex | null;
  surname: string;
  taxCode?: string | null;
  nextAvailability?: number | null;
};
export enum Sex {
  Female = 'FEMALE',
  Male = 'MALE',
}
export enum PaymentType {
  Deferred = 'DEFERRED',
  Online = 'ONLINE',
  Onlywithcoupon = 'ONLYWITHCOUPON',
  Onsite = 'ONSITE',
}
export enum License {
  None = 'NONE',
  Premium = 'PREMIUM',
  Standard = 'STANDARD',
}

export type SpecializationDetail = {
  _id?: string;
  channels?: string[];
  service?: string;
  specialization?: string;
};

export type SpecializationDoctor = Node & {
  _id: string;
  sex?: Sex;
};

export type Specialization = {
  detail?: SpecializationDetail;
  doctor?: SpecializationDoctor;
  favorite?: boolean;
  price?: number;
};
