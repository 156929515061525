'use client';

import { HomeHeroBannerStoryblok } from '@/types/types-storyblok';
import { Lora } from 'next/font/google';
import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx/lite';
import Image from 'next/image';
import { Suspense, useRef, useState } from 'react';
import { StoryblokComponent } from './storyblokComponent';
import { StoryblokReactComponentProps } from './componentsMap';
import { ELButton } from '@davincihealthcare/elty-design-system-react';

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

// eslint-disable-next-line no-restricted-syntax
export function StoryblokHomeHeroBanner({ component, story, additionalData }: StoryblokReactComponentProps<HomeHeroBannerStoryblok>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const [content] = component.content;
  const hasRichSubtitle = (component.richSubtitle?.content ?? []).length > 0;

  return (
    <section
      ref={containerRef}
      className="container relative mx-auto flex min-h-[718px] flex-col py-14 pt-20 md:px-2 lg:items-start lg:gap-10 lg:px-4 lg:py-0 lg:pt-28"
    >
      <div className="absolute inset-0 top-[2px] hidden h-full max-h-full w-full overflow-x-hidden lg:block lg:h-[718px]">
        <Image
          className="hidden object-contain object-right lg:block lg:translate-x-10 xl:translate-x-0"
          src={component.desktopBackgroundImage.filename}
          aria-hidden="true"
          alt={component.desktopBackgroundImage.alt ?? ''}
          fill
        />
      </div>

      <h1 className="relative z-[2] mb-10 text-center text-4xl leading-snug md:text-5xl md:leading-snug lg:mb-0 lg:max-w-[700px] lg:text-left lg:leading-tight xl:inline-block xl:w-full xl:text-6xl xl:leading-snug">
        {render(component.title, {
          markResolvers: {
            [MARK_BOLD]: child => <span className={clsx(lora.className, 'text-elty-green')}>{child}</span>,
          },
        })}
      </h1>
      {component.subtitle && !hasRichSubtitle && (
        <div className="flex-start z-[200] flex flex-col gap-4 lg:max-w-[480px]">
          <p className={`text-xl text-neutral-darker`}>{component.subtitle}</p>
        </div>
      )}
      {hasRichSubtitle && (
        <div className={clsx('flex-start z-[200] mb-4 flex flex-col gap-4 lg:mb-0 lg:max-w-[480px]')}>
          <div className={`text-ellipsis text-xl text-neutral-darker [&>p]:mb-2 ${!isExpanded ? 'line-clamp-2' : ''}`}>
            {render(component.richSubtitle, {
              markResolvers: {
                [MARK_BOLD]: child => <span className={'font-semibold'}>{child}</span>,
              },
            })}
          </div>
          <div>
            <ELButton
              label={isExpanded ? 'Mostra meno' : 'Leggi di più'}
              color="primary"
              variant="text"
              size="small"
              aria-label={isExpanded ? 'Mostra meno' : 'Leggi di più'}
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </div>
        </div>
      )}
      {content.component === 'homeHeroBannerRichtextWithCta' && (
        <div className="text-center lg:w-full lg:text-left xl:mx-auto xl:max-w-screen-xl">
          <div className="w-full px-4 lg:w-5/12 lg:pl-0 xl:pr-0 ">
            {render(content.content, {
              markResolvers: {
                [MARK_BOLD]: child => <span className="font-medium">{child}</span>,
              },
            })}
          </div>
        </div>
      )}
      <div className="lg:hidden">
        <Suspense>
          <StoryblokComponent story={story} component={content} additionalData={additionalData} />
        </Suspense>
      </div>
      <Image
        className="h-auto w-full object-contain px-4 md:hidden"
        src={component.mobileBackgroundImage.filename}
        aria-hidden="true"
        height={262}
        width={262}
        alt={component.mobileBackgroundImage.alt ?? ''}
      />

      <Image
        className="hidden h-auto w-full object-contain px-11 md:block lg:hidden"
        src={component.tabletBackgroundImage.filename}
        aria-hidden="true"
        height={718}
        width={718}
        alt={component.tabletBackgroundImage.alt ?? ''}
      />
      <div className="hidden lg:block">
        <Suspense>
          <StoryblokComponent story={story} component={content as any} additionalData={additionalData} />
        </Suspense>
      </div>
    </section>
  );
}
