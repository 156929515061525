import { LISTING_PAGE_PREFIXED_CALLCENTER_TELEPHONE_NUMBER, LISTING_PAGE_FORMATTED_CALLCENTER_TELEPHONE_NUMBER } from '@/costants';
import { ELButton } from '@davincihealthcare/elty-design-system-react';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

export const BookingActions: React.FC<{
  onClick: () => void;
  size?: 'small' | 'medium';
  loading: boolean;
  showPhoneNumber?: boolean;
}> = ({ onClick, size = 'medium', loading, showPhoneNumber = true }) => (
  <div className="flex flex-col justify-center gap-3 lg:grid lg:grid-cols-2 lg:pt-6">
    <ELButton
      trailingIcon={<ArrowRightIcon />}
      color="primary"
      variant="filled"
      size={size}
      disabled={loading}
      aria-label="Scegli orario"
      label="Scegli orario"
      onClick={onClick}
    />

    {showPhoneNumber && (
      <div className="w-full text-ellipsis py-3 text-center text-base font-normal text-primary lg:py-0">
        {!loading ? (
          <>
            oppure chiama il{' '}
            <a
              className="whitespace-nowrap text-base font-semibold underline"
              href={`tel:${LISTING_PAGE_PREFIXED_CALLCENTER_TELEPHONE_NUMBER}`}
            >
              {LISTING_PAGE_FORMATTED_CALLCENTER_TELEPHONE_NUMBER}
            </a>
          </>
        ) : (
          <span className="opacity-50">caricamento in corso...</span>
        )}
      </div>
    )}
  </div>
);
