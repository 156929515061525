'use client';
import { useTantoSvagoStore } from '@/store';
import clsx from 'clsx/lite';
import Container from '@/app/_components/container';
import { ROUTES } from '@/routes';
import Image from 'next/image';
import { DAVINCI_CDN } from '@/costants/defaultValues';
import Link from 'next/link';
import { useIsTantosvago } from '@/app/_hooks/useIsTantosvago';
import HeaderTantosvago from '@/app/_components/layouts/headerTantosvago';

type HeaderProps = React.PropsWithChildren & {
  color?: 'neutral' | 'primary';
  childrenClassName?: string;
  shouldShowTantoSvago?: boolean;
};

const HeaderLogo: React.FC<{ isTantosvago: boolean; children: React.ReactNode }> = ({ isTantosvago, children }) => {
  if (isTantosvago) {
    return <>{children}</>;
  }
  return (
    <Link href={ROUTES.HOME.pathname} legacyBehavior>
      <a href={ROUTES.HOME.pathname} className="w-full">
        {children}
      </a>
    </Link>
  );
};

const Header = ({ children, color = 'neutral', shouldShowTantoSvago = false, childrenClassName }: HeaderProps) => {
  const { userInfo, logoImageUrl, callbackUrl } = useTantoSvagoStore();
  const logoAltText = 'Elty logo';

  const logoFull = `${DAVINCI_CDN}/elty/elty-logo.svg`;
  const logoFullWhite = `${DAVINCI_CDN}/elty/elty-logo-white.svg`;
  const logoShort = `${DAVINCI_CDN}/elty/elty-logo-mobile.svg`;
  const logoShortWhite = `${DAVINCI_CDN}/elty/elty-logo-white-mobile.svg`;

  const hasTantosvagoToken = useIsTantosvago();
  const isTantosvago = hasTantosvagoToken && shouldShowTantoSvago;

  return (
    <header className={clsx('relative', isTantosvago ? 'h-44 md:h-40' : 'h-16 md:h-20')} data-cy="header" id="header-elty">
      {isTantosvago && (
        <HeaderTantosvago
          name={userInfo.firstName}
          surname={userInfo.lastName}
          logo={logoImageUrl}
          onGoBack={() => (window.location.href = new URL(callbackUrl).href)}
        />
      )}
      <Container
        className={clsx(
          'fixed z-[250] w-full',
          isTantosvago ? 'top-[98px] md:top-[80px]' : '',
          color === 'neutral' && 'bg-neutral-surface shadow-[0px_5px_3px_0px_#00000008]',
          color === 'primary' && 'bg-primary',
        )}
      >
        <div className={clsx('grid h-16 place-items-center md:h-20', children ? 'grid-cols-[auto_1fr] md:gap-x-4' : 'grid-cols-1')}>
          <div className={clsx('relative md:h-14 md:w-32', children ? 'h-11 w-14' : 'h-16 w-20')} data-cy="header-company-logo">
            <HeaderLogo isTantosvago={isTantosvago}>
              {children ? (
                <>
                  <Image src={color === 'neutral' ? logoShort : logoShortWhite} className="block md:hidden" alt={logoAltText} fill />
                  <Image src={color === 'neutral' ? logoFull : logoFullWhite} className="hidden md:block" alt={logoAltText} fill />
                </>
              ) : (
                <Image src={color === 'neutral' ? logoFull : logoFullWhite} alt={logoAltText} fill />
              )}
            </HeaderLogo>
          </div>
          {children && <div className={childrenClassName}>{children}</div>}
        </div>
      </Container>
    </header>
  );
};

export default Header;
