import screens from '@/utils/tailwindcss.json';
import { useEffect, useState } from 'react';

export type UseIsScreenOptions = { andSmaller?: boolean };

export const useIsMobile = ({ andSmaller = true }: UseIsScreenOptions = {}) => {
  return useIsScreen('sm', andSmaller);
};

export const useIsTablet = ({ andSmaller = true }: UseIsScreenOptions = {}) => {
  return useIsScreen('md', andSmaller);
};

export const useIsDesktop = ({ andSmaller = true }: UseIsScreenOptions = {}) => {
  return useIsScreen('lg', andSmaller);
};

// media observer for checking if screen size is less than or equal to the given size
const useIsScreen = (size: keyof typeof screens, isMaxWidth = true) => {
  const query = isMaxWidth ? `(max-width: ${screens[size]}px)` : `(min-width: ${screens[size]}px)`;

  const [isScreen, setIsScreen] = useState(() => {
    if (typeof window === 'undefined') return false;
    return window.matchMedia(query).matches;
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const media = window.matchMedia(query);

    setIsScreen(media.matches);
    const handler = ({ matches }: MediaQueryListEvent) => {
      setIsScreen(matches);
    };

    media.addEventListener('change', handler);
    return () => media.removeEventListener('change', handler);
  }, [query]);

  return isScreen;
};
