import { StoryblokReactComponent } from './componentsMap';
import { MedicalVisitListStoryblok } from '@/types/types-storyblok';
import Image from 'next/image';
import { StoryblokComponent } from '@/app/(booking)/prenota-visita/_storyblok/storyblokComponent';
import { resolveWebLink } from '@/storyblok/utils/link';
import Link from 'next/link';
import clsx from 'clsx/lite';
import { defaultServicesForPrenotaVisita } from '@scripts/staticBuildUtils';
import { StoryblokMedicalVisitCard } from '@/app/(booking)/prenota-visita/_storyblok/medicalVisitCard';

const defaultCardsForPrenotaVisitaService = defaultServicesForPrenotaVisita.map(
  service =>
    ({
      _uid: `${service.slug}-card`,
      link: {
        id: '',
        url: `/prenota-visita/${service.slug}`,
        linktype: 'url',
        fieldtype: 'multilink',
        cached_url: `/prenota-visita/${service.slug}`,
      },
      title: `${service.title}`,
      component: 'medicalVisitCard',
    }) as const,
);

const mapDefaultCardsForPrenotaVisitaServiceLocation = (services: { slug: string; title: string }[]) => {
  return services.map(
    service =>
      ({
        _uid: `${service.slug}-card`,
        link: {
          id: '',
          url: `/prenota-visita/${service.slug}/[[city]]`,
          linktype: 'url',
          fieldtype: 'multilink',
          cached_url: `/prenota-visita/${service.slug}/[[city]]`,
        },
        title: `${service.title} a [[city]]`,
        component: 'medicalVisitCard',
      }) as const,
  );
};

export const StoryblokMedicalVisitList: StoryblokReactComponent<MedicalVisitListStoryblok> = ({ component, story, additionalData }) => {
  const urlLink = component?.urlLink?.[0];
  const additionalLocationName = additionalData?.locationName ?? '';

  const hasCustomList = (component.list?.length ?? 0) > 0;
  const isPrenotaVisitaServiceLocation = additionalData.pageKey === 'pv-service-location';
  const isPrenotaVisitaService = additionalData.pageKey === 'pv-service';

  const defaultCardsForPrenotaVisitaServiceLocation = mapDefaultCardsForPrenotaVisitaServiceLocation(
    additionalData.topServicesAtLocation ?? [],
  );

  return (
    <section
      className={clsx(
        component.background === 'neutral' ? 'bg-neutral-surface-raised' : 'bg-white',
        'shadow-1 flex flex-col items-center justify-center gap-7 bg-[#F3F8F8] px-4 py-14 md:px-20 lg:py-20',
      )}
    >
      {component.title && <h2 className="text-center text-3xl">{component.title.replaceAll('[[city]]', additionalLocationName ?? '')}</h2>}
      {component.image?.filename && (
        <Image src={component.image.filename} alt={component.image.alt ?? ''} aria-hidden="true" height={200} width={200} />
      )}
      <p className="max-w-[740px] text-center text-xl">{component.description}</p>
      <ul className="grid w-full grid-cols-1 justify-between gap-x-24 gap-y-6 md:grid-cols-3">
        {hasCustomList ? (
          (component.list ?? []).map(card => (
            <StoryblokComponent story={story} key={card._uid} component={card} additionalData={additionalData} />
          ))
        ) : (
          <>
            {isPrenotaVisitaServiceLocation &&
              defaultCardsForPrenotaVisitaServiceLocation.map(card => (
                <StoryblokMedicalVisitCard key={card._uid} component={card} story={story} additionalData={additionalData} />
              ))}

            {isPrenotaVisitaService &&
              defaultCardsForPrenotaVisitaService.map(card => (
                <StoryblokMedicalVisitCard key={card._uid} component={card} story={story} additionalData={additionalData} />
              ))}
          </>
        )}
      </ul>
      {urlLink?.link && urlLink?.title && (
        <div className="mt-16 flex justify-center font-semibold text-primary underline">
          <Link href={resolveWebLink(urlLink.link).replaceAll('[[city]]', additionalData.location ?? '')}>
            {urlLink.title.replaceAll('[[city]]', additionalLocationName)}
          </Link>
        </div>
      )}
    </section>
  );
};
