import { Sheet } from '@/app/_components/Sheet';
import Modal from '@/app/_components/modal';
import { ELButton, ELLoader } from '@davincihealthcare/elty-design-system-react';
import SpecialitiesAutocomplete from '@/app/_components/specialitiesAutocomplete';
import { AutocompleteSpecialitiesItemOption } from '@/app/_components/specialitiesAutocomplete/types';
import { WidgetNavigator } from '@/app/_components/widgets/widgetNavigator';
import { useState } from 'react';
import { AvailabilityModal } from '@/app/_components/widgets/listing/cardV2/AvailabilityModal';
import { SlotAvailability } from '@/app/_components/widgets/listing/types';
import { AggregatorSpeciality } from '@/types-aggregatore';
import { useIsTablet } from '@/app/_hooks/useIsScreen';

type RecapChangeSpecializationProps = {
  onNewSlot: (s: SlotAvailability) => void;
  onCancel: () => void;
  workgroupId: string;
  workgroupName: string;
  domiciliaryCare: boolean;
  specializations: AggregatorSpeciality[];
  isLoading?: boolean;
};

const RecapChangeSpecialization = ({
  onNewSlot,
  onCancel,
  workgroupId,
  domiciliaryCare,
  specializations,
  workgroupName,
  isLoading,
}: RecapChangeSpecializationProps) => {
  const isTabletAndSmaller = useIsTablet();
  const [newSpecialization, setNewSpecialization] = useState<AutocompleteSpecialitiesItemOption | null>(null);

  if (!workgroupId) return null;

  if (newSpecialization && newSpecialization.doctor?.serviceId && newSpecialization.doctor?.id)
    return (
      <AvailabilityModal
        onClose={onCancel}
        onSubmit={onNewSlot}
        specialityId={newSpecialization.doctor.serviceId}
        doctorId={newSpecialization.doctor.id}
        workgroupId={workgroupId}
        initialSelectedDay={null}
      />
    );

  return isTabletAndSmaller ? (
    <Modal onClose={onCancel} className="m-0 h-full max-h-full w-full max-w-full px-4 pb-5" isModalOpen>
      {isLoading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <ELLoader />
        </div>
      ) : (
        <div className="flex h-full w-full flex-col overflow-hidden">
          <WidgetNavigator
            backButtonEnabled={!!newSpecialization}
            className="my-5"
            description={newSpecialization ? 'Seleziona una data' : 'Seleziona una prestazione'}
            onClose={onCancel}
            onTurnBack={() => setNewSpecialization(null)}
          />

          <SpecialitiesAutocomplete
            clinicName={workgroupName}
            clinicDomiciliary={domiciliaryCare ?? false}
            specialities={specializations ?? []}
            setSpecialitySelected={setNewSpecialization}
            id="autocomplete-specialities"
            listClassName="max-h-80-screen"
          />
        </div>
      )}
    </Modal>
  ) : (
    <Sheet onClose={onCancel} isOpen>
      {isLoading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <ELLoader />
        </div>
      ) : (
        <div className="flex h-full flex-col">
          <div className={`relative flex h-full flex-col gap-6 px-16 pt-20`}>
            <h3 className="text-base font-semibold text-primary">Ecco tutte le prestazioni disponibili in questa clinica</h3>

            <SpecialitiesAutocomplete
              clinicName={workgroupName}
              clinicDomiciliary={domiciliaryCare ?? false}
              specialities={specializations ?? []}
              setSpecialitySelected={setNewSpecialization}
              id="autocomplete-specialities"
              listClassName="max-h-full"
            />

            <div className="z-50 bg-transparent pb-10">
              <div className="h-10 w-full bg-gradient-to-t from-white to-transparent" />

              <div className="mb-auto flex gap-10">
                <ELButton
                  aria-label="Annulla le modifiche"
                  variant="text"
                  color="primary"
                  size="medium"
                  label="Annulla le modifiche"
                  onClick={onCancel}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Sheet>
  );
};

export default RecapChangeSpecialization;
