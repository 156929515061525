'use client';
import { StoryblokReactComponent } from '@/app/(booking)/prenota-visita/_storyblok/componentsMap';
import { FaqCardStoryblok } from '@/types/types-storyblok';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx/lite';
import { useState } from 'react';

export const StoryblokFaqCard: StoryblokReactComponent<FaqCardStoryblok> = ({ component }) => {
  const [isShowing, handleShow] = useState(false);
  return (
    <div className="flex w-full flex-col rounded-lg bg-neutral-surface p-5">
      <div className="faq flex flex-col gap-6">
        <button aria-label={isShowing ? 'Nascondi' : 'Mostra'} onClick={() => handleShow(!isShowing)}>
          <p className="text-neutral-dark inline-flex w-full items-center justify-between text-left text-lg">
            {component.question}
            <span className="ml-4 lg:ml-8">
              {isShowing ? <ChevronUpIcon className="h-6 w-6 text-secondary" /> : <ChevronDownIcon className="h-6 w-6 text-secondary" />}
            </span>
          </p>
        </button>
        <p className={clsx('flex flex-col gap-y-6 text-base text-neutral-lighter', isShowing ? 'visible' : 'hidden')}>{component.answer}</p>
      </div>
    </div>
  );
};

export default StoryblokFaqCard;
